import React from "react";
import styled from "styled-components";
import { PhonenumberIcon } from "./CustomIcons";

const CallUsStyle = styled.div`
  a {
    border-radius: 200px;
    padding: 10px;
    border: 1px solid ${({ theme: { palette } }) => palette.primary.main};
    align-items: center;
    display: flex;
    gap: 10px;
    color: ${({ theme: { palette } }) => palette.primary.main};
  }
  text-align: center;
`;

export default function CallUs({ phonenumber }) {
  return (
    <>
      <CallUsStyle className="call-us-wrapper">
        <a className="call-us" href={`tel:${phonenumber}`}>
          <PhonenumberIcon />
          <span>{phonenumber}</span>
        </a>
      </CallUsStyle>
    </>
  );
}
