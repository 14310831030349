import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import styled from "styled-components";
import CallUs from "./CallUs";
import { CloseMenuIcon, MenuIcon } from "./CustomIcons";
import { ContextAPI } from "./Layout";
import Logo from "./Logo";
import Navbar from "./Navbar";

const NavigationStyle = styled.header`
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > ul {
    display: none;
  }

  .call-us-wrapper {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    height: 80px !important;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 115px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-area {
      display: flex;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;
      padding: 10px 20px;
    }
    & > ul,
    .call-us-wrapper {
      display: block;
    }
  }
`;

export default function Navigation() {
  const { header, menus } = useStaticQuery(graphql`
    query headerQuery {
      header: allStrapiHeader {
        nodes {
          logo {
            localFile {
              url
            }
          }
          phonenumber
        }
      }
      menus: allStrapiNavigation {
        nodes {
          id
          slug
          path
          order
          title
          items {
            id
            path
            slug
            title
            order
            items {
              id
              path
              slug
              title
              order
              items {
                id
                path
                slug
                title
                order
              }
            }
          }
        }
      }
    }
  `);
  const { logo, phonenumber } = header.nodes[0];
  const { isOpen, setIsOpen } = useContext(ContextAPI);

  return (
    <NavigationStyle>
      <Logo image={logo} />
      {/* <Menu menus={menus} logo={logo} phonenumber={phonenumber} /> */}
      <div className="navigation">
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <MenuIcon /> : <CloseMenuIcon />}
        </button>
        <div
          className={`${isOpen ? "nav-area slide-in" : "nav-area slide-out"}`}
        >
          <Navbar menuItems={menus} />
        </div>
      </div>
      <CallUs phonenumber={phonenumber} />
    </NavigationStyle>
  );
}
