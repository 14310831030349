import { createGlobalStyle } from "styled-components";
import Palatte from "../theme/Palette";

const GlobalStyle = createGlobalStyle`

  html {
    scroll-behavior: smooth;
  }

  // resets
  *, *::after, *::before {
    box-sizing: border-box;
  }

  li, ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    display: flex;
    border: 0;
    background-color: transparent;
  }

  .stop-scrolling {
    overflow: hidden;
  }

  .menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}


.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  flex-direction: column;
  & a {

  }
}

.menu-items {
  position: relative;
  font-size: 15px;
}

ul.menus a, ul.menus button {
    text-transform: capitalize;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.nav-area {
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 320px;
  transition: transform 500ms ease-in-out;
  box-shadow: 5px 0px 10px rgba(0,0,0,0.2);
  &.slide-in {
    transform: translateX(0);
  }
  &.slide-out {
    transform: translateX(-100%);
  }
}

.menu-items [aria-current="page"] {
  background: ${Palatte.secondary.main};
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
  color: white;
  border-radius: 10px;
  &:hover {
    color: ${Palatte.primary.main};
  }
}

li.menu-items button a {
    background: transparent!important;
    color: black!important;
    box-shadow: none!important;
    display: block;
    width: 100%;
}

.highlight {
    color: ${({ theme: { palette } }) => palette.secondary.main};
}

.navbar-wrapper {
  margin: 30px 0;
}


@media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    .menus {
      flex-direction: unset;
    }
    .nav-area {
      background: transparent;
      position: unset;
      top: unset;
      bottom: unset;
      left: unset;
      z-index: unset;
      width: unset;
      box-shadow: none;
      &.slide-in {
        transform: unset;
      }
      &.slide-out {
        transform: unset;
      }
    }
    .navigation {
      & > button {
        display: none;
      }
    }
  }

`;

export default GlobalStyle;
