import React from "react";
import ListItem from "./ListItem";

export default function ListItems({ items }) {
  return (
    <>
      {items.map(({ item, internal }) => (
        <ListItem key={item.id} {...item} isInternal={internal} />
      ))}
    </>
  );
}
