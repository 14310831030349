import { Link } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  const isServerSide = () => typeof window !== "undefined";
  useEffect(() => {
    const handler = event => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    if (isServerSide()) {
      window.innerWidth > 990 && setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (isServerSide()) {
      window.innerWidth > 990 && setDropdown(false);
    }
  };

  const closeDropdown = () => dropdown && setDropdown(false);

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.path && items?.items?.length > 0 ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown(prev => !prev)}
          >
            {isServerSide() && window.innerWidth < 990 && depthLevel === 0 ? (
              items.title
            ) : (
              <Link to={items.path}>{items.title}</Link>
            )}

            {depthLevel > 0 &&
            isServerSide() &&
            window.innerWidth < 990 ? null : depthLevel > 0 &&
              isServerSide() &&
              window.innerWidth > 990 ? (
              <span className="arrow" />
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.items}
            dropdown={dropdown}
          />
        </>
      ) : !items.path && items.items ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown(prev => !prev)}
          >
            {items.title} {depthLevel > 0 ? <span className="arrow" /> : ""}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.items}
            dropdown={dropdown}
          />
        </>
      ) : (
        <Link to={items.path}>{items.title}</Link>
      )}
    </li>
  );
};

export default MenuItems;
