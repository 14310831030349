import { createGlobalStyle } from "styled-components";

import { pxToRem } from "../utils";

const TypographyStyle = createGlobalStyle`

  @font-face {
    font-family: "montserrat";
    src: url('../assets/fonts/Montserrat.woff2') format('woff2'),
        url('../assets/fonts/Montserrat.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    display: swap;
  }

  @font-face {
    font-family: "domainetext";
    src: url('../assets/fonts/domaineText.woff2') format('woff2'),
        url('../assets/fonts/domaineText.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    display: swap;
  }


  html {
    font-family: "domainetext", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 100%; // 16px
  }

  h3, p, h2 {
    margin: 0;
  }
  
  p {
    line-height: 1.5;
  }

  h2, h1, h3, h4, h5, h6 {
    color: ${({ theme: { palette } }) => palette.primary.main};
    font-family: "montserrat";
  }

  h1 {
    font-size: ${({ theme: { typography } }) =>
      `${pxToRem(typography.h1.lg)}!important`};
  }

`;

export default TypographyStyle;
