import { px } from "../utils";

const { default: styled } = require("styled-components");

const ContainerStyle = styled.div`
  padding: 0 ${px(5)};
  padding-bottom: 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    padding: 0 ${px(10)};
    padding-bottom: 0;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 ${px(20)};
    padding-bottom: 0;
  }
`;

export default ContainerStyle;
