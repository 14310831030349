import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import ContainerStyle from "../styles/ContainerStyle";
import { px } from "../utils";
import Copyright from "./Copyright";
import ListItems from "./ListItems";

const FooterStyle = styled.footer`
  background-color: ${({ theme: { palette } }) => palette.primary[700]};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  a {
    color: ${({ theme: { palette } }) => palette.primary[50]};
  }
  a {
    text-transform: capitalize;
  }
  h3 {
    text-transform: uppercase;
    color: ${({ theme: { palette } }) => palette.primary[200]};
    position: relative;
    display: inline-block;
    margin-bottom: ${px(7)};
    &::after {
      content: "";
      background-color: ${({ theme: { palette } }) => palette.primary[200]};
      width: 100%;
      height: 2px;
      position: absolute;
      display: block;
    }
  }
`;

const ListItemBlockStyle = styled.section`
  &:first-child {
    padding-top: 30px;
  }
  li {
    margin-bottom: ${px(3)};
    margin-left: 0 !important;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding-top: ${px(6.2)};
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-top: ${px(12.5)};
  }
`;

export default function Footer() {
  const {
    footer: { nodes },
  } = useStaticQuery(graphql`
    query footerQuery {
      footer: allStrapiFooter {
        nodes {
          firstListItem {
            id
            itemName
            itemUrl {
              id
              name
              url
            }
          }
          secondListItem {
            id
            itemName
            itemUrl {
              id
              name
              url
            }
          }
          thirdListItem {
            id
            itemName
            itemUrl {
              id
              name
              url
            }
          }
          copyRight
        }
      }
    }
  `);

  return (
    <FooterStyle>
      <ContainerStyle>
        <ListItemBlockStyle>
          <ListItems
            items={[
              {
                item: nodes[0].firstListItem,
                internal: false,
              },
              {
                item: nodes[0].secondListItem,
                internal: true,
              },
              {
                item: nodes[0].thirdListItem,
                internal: true,
              },
            ]}
          />
        </ListItemBlockStyle>
        <Copyright copyrightText={nodes[0].copyRight} />
      </ContainerStyle>
    </FooterStyle>
  );
}
