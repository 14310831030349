import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { px } from "../utils";

const ListItemStyle = styled.ul`
  margin-bottom: ${px(8.75)};
  &:not(:first-child) li {
    margin-left: ${px(5)};
  }
  li {
    color: ${({ theme: { palette } }) => palette.primary[50]};
    font-weight: bold;
    a {
      font-weight: bolder;
      letter-spacing: 1px;
    }
  }
`;

export default function ListItem({ itemName, itemUrl, isInternal }) {
  return (
    <ListItemStyle>
      <h3>{itemName}</h3>
      {itemUrl.map(({ url, name, id }) => (
        <li key={id}>
          {isInternal ? <Link to={url}>{name}</Link> : <a href={url}>{name}</a>}
        </li>
      ))}
    </ListItemStyle>
  );
}
