import React from "react";
import styled from "styled-components";

const CopyrightBlockStyle = styled.section`
  color: ${({ theme: { palette } }) => palette.primary[50]};
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 65px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme: { palette } }) => palette.primary[50]};
    border-radius: 50px;
  }
`;

export default function Copyright({ copyrightText }) {
  return (
    <CopyrightBlockStyle>
      <p>{copyrightText}</p>
    </CopyrightBlockStyle>
  );
}
