const Palatte = {
  primary: {
    main: "#27272A",
    900: "#18181B",
    700: "#3F3F46",
    600: "#52525B",
    500: "#71717A",
    400: "#a1a1aa",
    300: "#d4d4d8",
    200: "#E4E4E7",
    100: "#F4F4F5",
    50: "#FAFAFA",
  },
  secondary: {
    main: "#DC2626",
  },
};

export default Palatte;
