import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function Seo(props) {
  const { seo } = useStaticQuery(SeoQuery);
  const [nodesItems] = seo.nodes;
  const { metaDescription, siteName, metaTitle, sharedImage, favicon } =
    nodesItems;

  const { bodyClassName } = props;

  function getMetaTags() {
    var tags = [];
    var seoObject = {
      metaDescription,
      metaTitle,
      sharedImage,
      ...props.seo,
    };

    if (seoObject.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: seoObject.metaTitle,
        },
        {
          name: "twitter:title",
          content: seoObject.metaTitle,
        }
      );
    }

    if (seoObject.metaDescription) {
      tags.push(
        {
          name: "description",
          content: seoObject.metaDescription,
        },
        {
          name: "og:description",
          content: seoObject.metaDescription,
        },
        {
          name: "twitter:description",
          content: seoObject.metaDescription,
        }
      );
    }

    if (seoObject.sharedImage) {
      tags.push(
        {
          name: "image",
          content: sharedImage?.formats?.small?.url,
        },
        {
          name: "og:image",
          content: sharedImage?.formats?.small?.url,
        },
        {
          name: "twitter:image",
          content: sharedImage?.formats?.small?.url,
        }
      );
    }

    if (seoObject.keywords) {
      tags.push({
        name: "keywords",
        content: seoObject.keywords,
      });
    }

    if (seoObject.title) {
      tags.push({
        title: seoObject.title,
      });
    }

    return tags;
  }

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={metaTags[metaTags.length - 1].title || metaTitle}
      titleTemplate={`%s | ${siteName}`}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: favicon?.formats?.small?.url,
        },
      ]}
      meta={metaTags}
      bodyAttributes={{
        className: bodyClassName,
      }}
    />
  );
}

const SeoQuery = graphql`
  query allstrapiGlobal {
    seo: allStrapiGlobal {
      nodes {
        sharedImage {
          alternativeText
          formats {
            small {
              url
            }
          }
        }
        metaDescription
        metaTitle
        siteName
        favicon {
          formats {
            small {
              url
            }
          }
        }
      }
    }
  }
`;
