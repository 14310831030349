const Typography = {
  h1: {
    sm: 35,
    md: 39.8,
    lg: 42,
  },
  h2: {
    md: 33.2,
    sm: 28.2,
  },
  h3: {
    md: 27.6,
  },
  h4: {
    md: 23,
  },
  h5: {
    md: 20,
  },
  body1: 16,
  body2: 18,
  caption: 13.3,
};

export default Typography;
