import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import GlobalStyle from "../styles/GlobalStyle";
import TypographyStyle from "../styles/TypographyStyle";
import "normalize.css";
import Navigation from "./Navigation";
import Footer from "./Footer";
import styled, { ThemeProvider } from "styled-components";
import { Breakpoint, Palette, Spacing, Typography } from "../theme";
import BodyClassName from "react-body-classname";
import ContainerStyle from "../styles/ContainerStyle";
import { globalHistory } from "@reach/router";
import Seo from "./Seo";
const ClickoutSideStyle = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 98;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ContextAPI = React.createContext();

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const theme = useMemo(
    () => ({
      palette: Palette,
      typography: Typography,
      breakpoint: Breakpoint,
      spacing: Spacing,
    }),
    []
  );
  // const [toggle, setToggle] = React.useState(false);

  // const toggleSubItems = () => {
  //   setToggle(!toggle);
  // };

  const menuToggleOnClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setIsOpen(false);
      }
    });
  }, [setIsOpen]);

  return (
    <BodyClassName className={`${isOpen ? "stop-scrolling" : ""}`}>
      <ContextAPI.Provider
        value={{
          menuToggleOnClick,
          isOpen,
          setIsOpen,
          // toggleSubItems,
          // setToggle,
          // toggle,
        }}
      >
        <Seo />
        <ThemeProvider theme={theme}>
          {isOpen && (
            <ClickoutSideStyle
              onClick={isOpen ? () => setIsOpen(false) : undefined}
            />
          )}
          <ContainerStyle>
            <GlobalStyle />
            <TypographyStyle />
            <Navigation />
            {children}
            <Footer />
          </ContainerStyle>
        </ThemeProvider>
      </ContextAPI.Provider>
    </BodyClassName>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
